<template>
  <div>
    <StudentFAQ />
    <div>
      <h2>How do I perform a Weight and Balance check before my flight?</h2>
      <p>
        All Aircraft flight manuals (PoH) contain a section on <b>Weight and Balance</b>. Some flight manuals even provide
        a helpful example calculation. In addition, each <i>individual</i> aircraft is weighed and its flight manual will contain
        some weight and balance information about that specific aircraft:
      </p>
      <div class="list-container">
        <ul class="steps-list">
          <li><b>Basic Empty Weight (BEW)</b> a weight typically given in kilograms (kg) or pounds (lb)</li>
          <li><b>Arm</b> a measure of distance typically given in millimetres (mm) or inches (in)</li>
          <li><b>Moment</b> a measure of torque typically given in kilograms per millimetre (kg/mm) or pounds per inch (lb/in)</li>
        </ul>
      </div>
      <p>
        Importantly, given any two of these three values, you can derive the missing one:
      </p>
      <div class="list-container">
        <ul class="steps-list">
          <li>Moment = BEW x Arm</li>
          <li>BEW = Moment &div; Arm</li>
          <li>Arm = Moment &div; BEW</li>
        </ul>
      </div>
      <p>
        Flightscope Aviation maintains aircraft flight manuals on our website under
        <router-link to="//aircraft-documentation">Aircraft Documentation</router-link>.
        This allows you to practice and perform Weight and Balance calculations, on real aircraft, at home. However, it is
        <b>important</b> that you reference the actual flight manual on the day of your flight to ensure that you have used the correct
        and any updated figures.
      </p>
      <p>
        It is a <b>pilot responsibility</b> to perform a Weight and Balance calculation prior to <b>every</b> flight to ensure that you
        are operating within required limitations. It is not only required by regulations <i>(CAO 20.7.4)</i>, but operating outside of
        weight limits or balance limits is extremely unsafe &mdash; you only need to peruse accident and incident reports to learn more
        about that!
      </p>
      <p>
        All Flightscope Aviation staff are willing and open to help you with learning more about your pre-flight requirements, including
        Weight and Balance. If you have a quick question, just ask! If you feel you need a bit more help than that, please ring the office
        and schedule a briefing session with an instructor. We want you all to have fun, and be safe while doing so.
      </p>
    </div>
  </div>
</template>
<script>
import StudentFAQ from '@/components/StudentFAQ.vue'

export default {
  components: {
    StudentFAQ
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_presets.scss';

.list-container {
  display: inline-block;
}

.steps-list {
  list-style-type: square;
  text-align: left;
}
</style>
